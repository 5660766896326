<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="/elevator-iot/data/event/page">
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="终端编号">
            <el-input v-model.trim="filter.deviceNo" clearable></el-input>
          </vm-search>
          <vm-search label="事件代码">
            <el-input v-model.trim="filter.eventCode" clearable></el-input>
          </vm-search>
          <vm-search label="时间范围">
            <el-date-picker
              v-model="filter.seTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleTime">
            </el-date-picker>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="deviceNo" label="终端编号" align="center"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.elevatorDetail.open(scope.row.elevatorId)">{{scope.row.regCode}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="电梯名称" align="center"></el-table-column>
      <el-table-column prop="eventCode" label="事件代码" align="center"></el-table-column>
      <el-table-column prop="faultName" label="事件名称" align="center"></el-table-column>
      <el-table-column prop="time" label="发生时间" align="center"></el-table-column>
    </vm-table>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
  </div>
</template>
<script>
  import elevatorDetail from "@/views/elevator/ElevatorDetail";
  export default {
    components: {elevatorDetail},
    data() {
      return {
        filter: {
          regCode: "",
          deviceNo: "",
          eventCode: "",
          startTime: "",
          endTime: "",
          seTime: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      handleTime() {
        console.log(this.filter.seTime);
        if(this.filter.seTime !=null) {
          this.filter.startTime = this.filter.seTime[0];
          this.filter.endTime = this.filter.seTime[1];
        }
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
